.unique-collection-wrapper {
	@extend .fungible-campaign-wrapper;

	.unique-collection-form {
		width: 100%;
		margin-top: 32px;

		input {
			border-radius: $border-radius-input;
		}

		.ant-form-item-label > label {
			font-size: $font-size-sm;
			font-weight: 700;
			color: $color-text-1;
		}

		.ant-form-vertical .ant-form-item-label {
			padding-bottom: 0;
		}

		.submit-buttons {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin-top: 40px;
		}
	}

	.ant-upload.ant-upload-select {
		.primary-btn {
			min-width: 260px !important;
			min-height: 42px;
		}
	}

	.ant-upload-list-picture .ant-upload-list-item {
		border-radius: $border-radius-wrapper;
	}
}
