.input-modal {
	.ant-modal-content {
		border-radius: $border-radius-wrapper;
	}

	.ant-modal-header {
		border-bottom: 2px solid $color-4;
		border-radius: $border-radius-wrapper $border-radius-wrapper 0 0;
	}

	.ant-modal-close {
		border: 0;
		top: 5px;
		right: 0px;
		width: fit-content;
		&:hover {
			border: 0;
		}
	}

	.ant-modal-body {
		padding-bottom: 8px;
	}

	.ant-modal-title {
		font-weight: 700;
		font-size: $font-size-xxmd;
		line-height: 32px;
		color: $color-text-1;
	}

	.form-input {
		border: $primary-border;
		border-radius: $border-radius-input;
	}

	.ant-modal-footer {
		text-align: left;
		padding: 0 25px 25px 25px;

		.ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
			margin-left: auto;
		}
	}

	.ant-form-item-explain {
		margin-top: 5px;
	}

	.body-input {
		border: $primary-border;
		height: 120px;
		width: 100%;
	}
}
