@mixin custom-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 32px;
	width: fit-content;
	min-width: max-content;
	height: fit-content;
	font-weight: 700;
	border-radius: $border-radius-btn;
	border: none;
	cursor: pointer;
}

.primary-btn {
	@include custom-btn;
	background: $color-1;
	color: $color-text-3;
	&:hover {
		background: $hover-color;
	}
}

.secondary-btn {
	@include custom-btn;
	background: $color-2;
	color: $color-text-1;
	box-shadow: inset 0px 0px 0px 1px $color-1;
	&:hover {
		box-shadow: inset 0px 0px 0px 1.5px $color-1;
	}
}

.tertiary-btn {
	@include custom-btn;
	background: $color-2;
	color: $color-text-2;
	border: 1px solid $color-4;
	font-weight: 400;
	font-size: $font-size-xxsm;
	height: 32px;
	&:hover {
		background: $color-1;
		color: $color-2;
	}
}

.disabled-btn {
	@include custom-btn;
	pointer-events: none;
	cursor: not-allowed;
	opacity: 0.65;
	filter: alpha(opacity=65);
	box-shadow: none;
	&:hover {
		cursor: not-allowed;
	}
}
