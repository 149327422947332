.fungible-nft-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: fit-content;
	border: $primary-border;
	border-radius: $border-radius-wrapper;
	padding: 16px;
	margin-top: 16px;

	.fungible-image-container {
		min-width: 230px;
		height: 230px !important;

		.ant-image {
			width: 100% !important;
			height: 100% !important;

			img {
				border-radius: $border-radius-wrapper;
				width: 100%;
				height: 100%;
			}
		}

		.ant-image-mask {
			border-radius: $border-radius-wrapper;
		}
	}

	.fungible-form {
		width: 100%;
		padding: 16px 24px;

		input {
			border-radius: $border-radius-input;
		}

		.ant-input-number {
			border-radius: $border-radius-input;
		}

		.ant-input-number-handler-wrap {
			border-radius: 0 $border-radius-input $border-radius-input 0;
		}

		.ant-form-item-label > label {
			font-size: $font-size-sm;
			font-weight: 700;
			color: $color-text-1;
		}
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		text-align: center;
		border-radius: $border-radius-wrapper;
		background: $color-2 !important;
		color: $color-text-1;
	}
}

.collectible-nft-container {
	@extend .fungible-nft-container;

	.fungible-image-container {
		min-width: 290px;
		height: 290px !important;
	}
}

.ant-select-dropdown {
	border-radius: $border-radius-wrapper !important;
}

.collectible-option {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
