.card {
	display: flex;
}

.nft-card {
	margin-top: 3.3vh;

	.card-wrapper {
		position: relative;
		margin: 0;
		width: 231px;
		height: 300px;
		border: $primary-border;
		border-radius: $border-radius-card;
		cursor: default;

		&:hover {
			box-shadow: unset;
			transition: unset;
		}

		.ant-image {
			width: 100%;
			height: 100%;
		}

		.img-wrapper {
			height: 55%;
			overflow: hidden;
			border-radius: $border-radius-card $border-radius-card 0 0;

			img {
				position: relative;
				top: -15px;
				width: 100%;
			}
		}

		.info-wrapper {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 15px;
			margin: 18px 14px;

			.campaign-name {
				font-size: $font-size-xxsm;
				color: $color-text-5;
			}

			.info-inner {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		.date-wrapper {
			width: 100%;
			height: 15%;
			display: flex;
			justify-content: center;
			background: $color-6;
			border-radius: 0 0 $border-radius-card $border-radius-card;
			padding: 12px 0;
			position: absolute;
			bottom: 0;

			span {
				font-size: $font-size-xsm;
			}

			.info {
				color: $color-text-4;
			}

			.date {
				color: $color-text-1;
				background: transparent;
				position: relative;
			}
		}
	}

	.spinner {
		position: relative;
	}
}
