.download-modal {
	@extend .input-modal;
	width: 480px !important;

	.ant-modal-header {
		border-bottom: none;
		padding-bottom: 0;
	}

	.download-content-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.download-description {
			color: $color-text-1;
			font-size: $font-size-xsm;
			font-weight: 400;
		}

		.btns-wrapper {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin: 48px 0 32px 0;

			.secondary-btn {
				min-width: 131px;
			}
		}

		.qrcode-container {
			padding-bottom: 21px;
		}
	}
}
