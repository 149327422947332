.assign-page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 8.2vh 22vw;

	.header {
		color: $color-text-1;
		font-size: $font-size-lg;
		align-self: flex-start;
	}

	.divider {
		width: 100%;
		border: $primary-border;
		margin: 48px 0;
	}

	.primary-btn,
	secondary-btn {
		min-width: 220px;
	}

	.assign-header-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.header {
			font-size: $font-size-xxmd;
		}
	}

	.content-wrapper {
		width: 90%;
		display: flex;
		flex-direction: column;

		.sub-header-wrapper {
			margin-top: 48px;
			display: flex;
			justify-content: space-between;
			gap: 20px;

			.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
				text-align: center;
				border-radius: $border-radius-wrapper;
				background: $color-2 !important;
				color: $color-text-1;
				height: 35px;
				width: 180px;
				display: flex;
				align-items: center;
				padding: 0 15px;
			}

			.secondary-btn {
				height: 35px;
				font-weight: 500;
			}

			.ant-input-affix-wrapper {
				height: 35px;
				border-radius: $border-radius-wrapper;
			}
		}

		.ant-select-dropdown {
			border-radius: $border-radius-wrapper !important;
		}

		.users-wrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 48px;
			gap: 30px;

			.user-box {
				height: 100%;
				width: 100%;
				max-width: 420px;
				min-height: 150px;
				background: $color-2;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				border-radius: $border-radius-card;
				padding: 24px 32px;
				box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

				.info-2 {
					color: $color-text-5;
				}

				.tertiary-btn {
					margin: 0;
					font-weight: 500 !important;
				}
			}
		}
	}
}

.campaigns-modal {
	@extend .delete-modal;

	.ant-modal-title {
		font-size: $font-size-md;
	}

	.ant-modal-body {
		padding: 18px 24px 0 24px;
	}

	.ant-checkbox-group {
		width: 100%;
		height: 220px;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		justify-content: flex-start;
		margin-top: 15px;
	}

	.modal-popup-collections-wrapper {
		.ant-checkbox-group-item {
			margin: 8px 8px 8px 0;
		}
	}
}

.invite-modal {
	@extend .campaigns-modal;

	.email-wrapper {
		width: 100%;

		.input {
			height: 35px;
			border-radius: $border-radius-wrapper;
		}
	}

	.divider {
		border: $primary-border;
		margin: 22px 0;
	}

	.header {
		margin: 20px 0;
		font-size: $font-size-md;
	}
}

.ant-popover-inner-content {
	.ant-checkbox-group {
		width: 100%;
		height: 260px;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	}

	.ant-checkbox-wrapper {
		margin: 10px 20px 0 0;
	}
}
