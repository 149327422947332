.admin-page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 8.2vh 14vw;

	.header {
		color: $color-text-1;
		font-size: $font-size-lg;
		align-self: flex-start;
	}

	.divider {
		width: 100%;
		border: $primary-border;
		margin: 48px 0;
	}

	.content-wrapper {
		width: 95%;
		display: flex;
		flex-direction: column;

		.sub-header-wrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: space-between;

			.tertiary-btn {
				font-weight: 500 !important;
				width: 160px;
			}

			.row {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}
	}

	.table-wrapper {
		@extend .homepage-wrapper;
		width: 100%;
		min-height: fit-content;
		display: flex;
		justify-content: center;
		margin-top: 32px;
	}
}
