.homepage-wrapper {
	min-height: 100vh;
	@extend .custom-selector;

	.ant-table table {
		border: 1px solid $color-3;
		border-radius: $border-radius-wrapper;
	}

	.ant-table-thead > tr > th {
		color: $color-text-1;
		font-weight: 700;
	}

	.ant-table-cell {
		color: $color-text-1;
	}

	table th:first-child {
		border-radius: $border-radius-card 0 0 0 !important;
	}
	table th:last-child {
		border-radius: 0 $border-radius-card 0 0 !important;
	}
	table tr:last-child td:first-child {
		border-radius: 0 0 0 $border-radius-card !important;
	}
	table tr:last-child td:last-child {
		border-radius: 0 0 $border-radius-card 0 !important;
	}

	.ant-pagination-prev .ant-pagination-item-link,
	.ant-pagination-next .ant-pagination-item-link {
		border-radius: $border-radius-wrapper;
		border: $primary-border;
		display: inline-flex;
		justify-content: center;
		align-items: center;

		&:hover {
			color: $color-4;
		}
	}

	.ant-pagination-item.ant-pagination-item-active {
		border: 1.5px solid $color-4;
		a {
			color: $color-text-1;
		}
	}

	.ant-pagination-item {
		border-radius: $border-radius-wrapper;
		border: $primary-border;
		a {
			color: $color-text-5;
			&:hover {
				color: $color-text-1;
			}
		}
	}
}

.sub-header-profile {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	height: 270px;
	padding: 6.3vh 12.44vw 7.22vh 12.44vw;
	margin-bottom: 48px;
	background: $color-6;

	.username {
		font-size: $font-size-xxmd;
		color: $color-1;
		font-weight: 500;
	}

	.ant-avatar.ant-avatar-icon > .anticon {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
	}

	.campaigns-info-wrapper {
		background: $color-2;
		display: flex;

		.info-box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 16px 25px;
			background: $color-2;
			border: $primary-border;

			&:first-child {
				border-top-left-radius: $border-radius-wrapper;
				border-bottom-left-radius: $border-radius-wrapper;
				border-right: 0;
			}

			&:nth-child(2) {
				border-right: 0;
			}

			&:last-child {
				border-top-right-radius: $border-radius-wrapper;
				border-bottom-right-radius: $border-radius-wrapper;
			}

			.label {
				font-size: $font-size-xxsm;
				color: $color-text-4;
				font-weight: 500;
			}

			.data {
				font-size: $font-size-xsm;
				color: $color-1;
				font-weight: 500;
			}
		}
	}
}

.campaigns-tab-wrapper {
	min-height: 25vh;
	width: 100%;
	display: flex;
	justify-content: center;

	.campaign-cards-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		gap: 30px;
	}

	.ant-tabs {
		width: 100%;
		display: flex;
		flex-grow: 1;
		justify-content: center;
		margin: 0 5.96vw;
		padding-bottom: 1vh;
	}

	.ant-tabs-content-holder {
		margin: 0 auto;
		max-width: 1278px;
		width: 100%;
	}

	.ant-tabs-content {
		display: inline;
	}

	.ant-tabs-tab-btn {
		color: $color-text-2;
	}

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: $color-text-2;
		font-weight: 500;
	}

	.ant-tabs-tab-btn:focus,
	.ant-tabs-tab-btn:active,
	.ant-tabs-tab-btn:hover {
		color: $color-text-2;
	}

	.ant-tabs-tab:hover {
		color: $color-text-2;
	}

	.ant-tabs-ink-bar {
		background: $color-1;
	}

	.options-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin: 3.3vh 0 4.4vh 0;

		.selector-wrapper {
			background: $color-2;
			border-radius: $border-radius-wrapper;
			display: flex;
			justify-content: center;
		}

		.dropdown-wrapper {
			background: $color-2;
		}

		// Search bar
		$tl: 0.5s; // transition length

		.search-box {
			position: absolute;
			transition: width $tl, border-radius $tl, background $tl, box-shadow $tl;
			width: 100%;
			height: 100%;
			border-radius: $border-radius-btn;
			border: $primary-border;
			padding-left: 35px;
			cursor: pointer;
			background: transparent;
			& + label .search-icon {
				color: $color-text-5;
			}
			&:hover {
				color: $color-text-2;
				border: $primary-border-hover;
				& + label .search-icon {
					color: $color-text-2;
				}
			}
			&:focus {
				border: $primary-border-hover;
				outline: 2px solid $color-3;
				box-shadow: none;
				font-weight: 400;
				color: $color-text-1;
				cursor: text;
				& + label .search-icon {
					color: $color-text-2;
				}
			}
		}

		::-webkit-input-placeholder {
			color: $color-text-2;
			font-weight: 400;
			font-size: $font-size-xsm;
		}

		#search-submit {
			display: none;
		}

		.search-icon {
			position: relative;
			color: $hover-color;
			margin: 0 10px;
			top: 8px;
			font-size: $font-size-sm;
			color: $color-text-5;
			cursor: pointer;
			&:hover {
				color: $hover-color;
			}
		}

		.search-box-wrapper {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.input-search {
			border-radius: $border-radius-btn;
			height: 100%;
			border: 0;
			margin: 0 20px;
			position: relative;
			z-index: 3;
			width: 100%;
		}
	}
}

.ant-select-clear {
	top: 45%;
	background: transparent;
}

.tertiary-btn {
	min-width: 122px;
	font-weight: 400 !important;
	margin: 12px;
	padding: 4px 8px;
	&:first-child {
		margin-bottom: 0;
	}
	&:hover {
		border-color: $color-1;
	}
}

.tertiary-btn-dropdown {
	@extend .tertiary-btn;
	margin: 0;
	min-width: 213px;
	padding: 0 12px;
	justify-content: space-between !important;
	&:hover {
		border-color: $color-1;
		color: $color-1;
	}

	svg {
		fill: $color-4;
	}
}

.dropdown-wrapper {
	border-radius: $border-radius-wrapper;

	.item {
		font-size: $font-size-xxsm !important;
		font-weight: 400;
	}
}

// AntD selector & dropdown styles
.custom-selector {
	.ant-select-dropdown {
		border-radius: $border-radius-wrapper;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		min-width: 145px;
		text-align: center;
		border-radius: $border-radius-wrapper;
		background: $color-2 !important;
		height: 32px;
	}

	.ant-select-single .ant-select-selector .ant-select-selection-item {
		font-size: $font-size-xsm;
	}

	.ant-select-single.ant-select-open .ant-select-selection-item {
		color: $color-text-3;
		background: $color-1 !important;
		border-radius: $border-radius-wrapper;
		border-color: $color-4;
	}

	.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
		.ant-select-selector {
		border-color: $color-4;
		box-shadow: none;
		padding: 0;
		transition: all 0s;
	}

	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		color: $color-1;
		background: $color-2 !important;
		font-weight: 400;
		border-radius: $border-radius-wrapper;
	}

	.ant-select-item-option-content {
		font-weight: 400;
		font-size: $font-size-xsm;
	}

	.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
		background: $color-1 !important;
		color: $color-text-3;
	}

	.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
		color: $color-text-3;
		background-color: $color-1 !important;
		border-color: $color-1;
	}

	.ant-select-selector {
		font-size: $font-size-xsm !important;

		span {
			font-size: $font-size-xsm !important;
		}
	}
}
