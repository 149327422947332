.create-campaign-page {
	margin: 8.2vh 24.5vw 0 24.5vw;

	.create-campaign-form {
		min-height: 100vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.ant-form {
			width: 100%;
		}

		.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
			border-radius: $border-radius-wrapper;
			background: $color-2 !important;
			color: $color-text-1;
		}

		.ant-select-dropdown {
			border-radius: $border-radius-wrapper !important;
		}

		.ant-select:not(.ant-select-customize-input)
			.ant-select-selector
			.ant-select-selection-search-input {
			font-size: $font-size-xsm;
			font-weight: 500;
		}

		textarea.ant-input {
			border-radius: $border-radius-wrapper;
		}

		.ant-picker-large {
			border-radius: $border-radius-wrapper;
			min-width: 200px;
		}

		.ant-picker-input > input {
			color: $color-text-1;
		}

		.header {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			color: $color-text-1;
			font-size: $font-size-lg;
			margin-bottom: 57px;
		}

		input {
			border-radius: $border-radius-input;
		}

		.ant-input-affix-wrapper {
			border-radius: $border-radius-input;
		}

		label {
			font-weight: 700 !important;
			color: $color-text-2;
		}

		.ant-form-vertical .ant-form-item-label {
			padding: 0;
		}

		.ant-upload-list-picture-card-container {
			height: 220px;
			width: 220px;
		}

		.ant-upload.ant-upload-select-picture-card {
			background: transparent;
			height: 100%;
			width: 100%;
			min-height: 200px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border: 1px dashed $color-4;
			border-radius: $border-radius-wrapper;
			padding: 24px 0;

			&:hover {
				border: 1px dashed $color-1;
			}
		}

		.upload-logo-wrapper {
			.ant-upload-list-picture-card-container {
				div {
					border-radius: 50%;
				}
			}

			.ant-upload.ant-upload-select-picture-card {
				height: 220px;
				width: 220px;
				border-radius: 50%;

				button {
					min-width: 88px;
				}
			}

			.ant-upload-list-picture-card .ant-upload-list-item {
				border: none;
				background-color: transparent;
			}
		}

		.submit-buttons {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin-top: 40px;

			button {
				min-width: 188px;
			}
		}

		.social-wrapper {
			margin: 34px 0 0 0;

			.ant-input {
				color: $color-text-1;
			}

			.ant-input-prefix {
				margin-right: 8px;
				border-right: $primary-border;

				svg {
					margin-right: 6px;
				}
			}

			.title {
				color: $color-text-1;
			}

			.links-container {
				margin-top: 12px;
			}
		}
	}
}

.nft-view-modal {
	.ant-modal-content {
		border-radius: $border-radius-wrapper;
	}

	.ant-modal-header {
		border-bottom: none;
		border-radius: $border-radius-wrapper $border-radius-wrapper 0 0;
	}
}

.rc-virtual-list-holder {
	scroll-behavior: auto;
}

// ANTD styles
.ant-picker-panel-container {
	border-radius: $border-radius-wrapper !important;
}

.ant-picker-cell-in-view {
	color: $color-text-1;
}

.ant-picker-panel .ant-picker-footer {
	a {
		color: $color-text-1;
		&:hover {
			color: $color-text-5;
		}
	}
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
	color: $color-text-1 !important;
	background: $color-3;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
	border-color: $color-4;
}

.ant-picker-content th {
	color: $color-text-1;
}

.ant-picker-header-view button {
	color: $color-text-1;
	&:hover {
		color: $color-text-5;
	}
}

.ant-form-item-explain-error {
	margin-top: 5px;
	font-size: $font-size-xsm;
}

.ant-input:placeholder-shown {
	font-size: $font-size-xsm;
	font-weight: 500;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
	font-size: $font-size-xsm;
	font-weight: 500;
}
