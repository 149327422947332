.header-wrapper {
	height: 73px;
	width: 100%;
	padding: 17px 10vw;
	background: $color-2;
	display: flex;
	justify-content: space-between;
	align-items: center;
	top: 0;
	position: sticky;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	z-index: 10;

	a,
	button {
		text-decoration: none;
		color: $color-text-1;
		background: transparent;
		border: none;
		cursor: pointer;

		&:hover {
			color: $hover-color;
			transition: all 200ms ease;
		}
	}

	.title {
		font-size: $font-size-title;
		font-weight: 700 !important;
		display: flex;
    flex-direction: row;
    align-items: inherit;

		span {
			color: $color-text-5;
			margin-left: 10px;
		}
	}

	.ant-avatar.ant-avatar-icon > .anticon {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
	}

	.ant-avatar {
		height: 40px;
		width: 40px;
		margin-left: 20px;
	}

	.options-wrapper {
		display: flex;
		align-items: center;
		a {
			white-space: nowrap;
			&:nth-child(2) {
				margin-left: 20px;
			}
		}
	}

	.avatar-dropdown {
		cursor: pointer !important;
	}

	.footer-content {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.title {
			color: $color-text-1;
		}

		.description {
			color: $color-text-4;
			font-size: $font-size-xxsm;
		}

		.description-wrapper {
			max-width: 265px;
		}
	}
}

.footer-wrapper {
	height: fit-content;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: $color-3;
	padding: 40px 10vw 24px 10vw;
	margin-top: 14vh;

	.footer-content {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.title {
			color: $color-text-1;

			.sub-title {
				color: $color-text-4;
				margin-left: 5px;
				font-size: $font-size-xsm;
			}
		}

		.description {
			color: $color-text-4;
			font-size: $font-size-xxsm;
		}

		.description-wrapper {
			max-width: 265px;
		}
	}

	.divider {
		border: $primary-border;
		margin: 16px 0;
		width: 100%;
	}

	.copyrights-wrapper {
		display: flex;
		justify-content: space-between;
		width: 100%;

		span {
			color: $color-text-4;
			font-size: $font-size-xsm;
		}

		a {
			text-decoration: none;
			color: $color-text-4;
			font-size: $font-size-xxsm;
			&:hover {
				color: $hover-color;
			}
		}
	}

	.social-wrapper {
		margin: 0 20px;

		.social-links {
			display: flex;
			justify-content: space-between;
		}
	}

	.marketing-portal-wrapper {
		max-width: 265px;
		position: relative;
		top: -15px;
		border: $primary-border;
		border-radius: $border-radius-wrapper;
		padding: 16px;
		&:hover {
			box-shadow: inset 0px 0px 0px 1.5px $color-4;
		}
	}
}

.divider {
	border: $primary-border;
	margin: 16px 0;
	width: 100%;
}

.copyrights-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;

	span {
		color: $color-text-4;
		font-size: $font-size-xsm;
	}

	a {
		text-decoration: none;
		color: $color-text-4;
		font-size: $font-size-xxsm;
		&:hover {
			color: $hover-color;
		}
	}
}

.social-wrapper {
	margin: 0 20px;
}

.marketing-portal-wrapper {
	max-width: 265px;
	position: relative;
	top: -15px;
	border: $primary-border;
	border-radius: $border-radius-wrapper;
	padding: 16px;
	&:hover {
		box-shadow: inset 0px 0px 0px 1.5px $color-4;
	}
}

.avatar-menu {
	border-radius: $border-radius-wrapper;
	width: fit-content;
	min-width: 120px;

	.avatar-menu-item {
		padding: 10px;
		color: $color-text-2;
	}
}
