.delete-modal {
	.ant-modal-content {
		border-radius: $border-radius-wrapper;
	}

	.ant-modal-header {
		border-bottom: 1px solid $color-4;
		border-radius: $border-radius-wrapper $border-radius-wrapper 0 0;
	}

	.ant-modal-footer {
		display: flex;
		justify-content: flex-end;
		gap: 15px;
		padding-bottom: 16px;
	}

	primary-btn,
	secondary-btn {
		min-width: 131px;
	}

	.secondary-btn:hover {
		color: $color-text-1;
		background: transparent;
	}

	.primary-btn:hover {
		color: $color-text-3;
	}
}
