.fungible-campaign-wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 8.2vh 24.5vw;

	.header-container {
		width: 100%;
		display: flex;
		justify-content: flex-start;

		.header {
			color: $color-text-1;
			font-size: $font-size-lg;
		}

		.sub-header {
			color: $color-text-1;
			font-size: $font-size-md;

			.label {
				color: $color-text-5;
				font-size: $font-size-md;
			}
		}
	}

	.upload-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 56px;

		.title {
			color: $color-text-1;
			font-size: $font-size-md;
		}

		.description {
			font-weight: 400;
			color: $color-text-1;
			font-size: $font-size-xxsm;
		}

		.image-uploader-wrapper {
			width: 100%;
			min-height: 200px;
			margin-top: 16px;
			background: $color-2;
			border: 1px dashed $color-4;
			border-radius: $border-radius-wrapper;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 24px 0;

			.primary-btn {
				min-width: 239px;
			}

			.ant-upload.ant-upload-select-picture-card {
				background: transparent;
				border: none;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.ant-upload.ant-upload-select-picture-card > .ant-upload {
				display: flex;
				flex-direction: column;
			}
		}
	}

	.uploaded-wrapper {
		width: 100%;
		margin-top: 57px;

		.header {
			color: $color-text-1;
			font-size: $font-size-md;
		}

		.submit-buttons {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin-top: 40px;
		}
	}
}
