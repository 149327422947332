// colors
$color-1: #494949;
$color-2: #ffffff;
$color-3: #efefef;
$color-4: #c4c4c4;
$color-5: #000000;
$color-6: #f8f8f8;
$color-7: #f9f9f9;
$color-8: #4285f4;

$color-text-1: #4f4f4f;
$color-text-2: #494949;
$color-text-3: #ffffff;
$color-text-4: #c4c4c4;
$color-text-5: #a5a5a5;
$color-text-6: #efefef;

$hover-color: #7c7c7c;
$hover-color-2: #5194ff;

// variables
$font-size-xxsm: 12px;
$font-size-xsm: 14px;
$font-size-sm: 16px;
$font-size-md: 18px;
$font-size-xmd: 22px;
$font-size-xxmd: 24px;
$font-size-lg: 28px;
$font-size-xlg: 48px;
$font-size-title: 20px;

$border-radius-btn: 4px;
$border-radius-card: 10px;
$border-radius-input: 6px;
$border-radius-wrapper: 6px;

$primary-border: 1px solid $color-4;
$primary-border-hover: 1.5px solid $color-4;

$item-info-wrapper: rgba(255, 255, 255, 0.9);

$box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
