.sub-header-campaign {
	height: fit-content;
	max-height: 345px;
	background: $color-3;
	position: relative;
	overflow: hidden;

	.campaign-banner-image {
		object-fit: contain;
		width: 100%;
		max-height: 345px;
		position: relative;
		z-index: 2;
	}

	.banner-blur-bg {
		background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), center no-repeat;
		background-size: auto, cover;
		filter: blur(50px);
		width: 100%;
		position: absolute;
		top: -100%;
		z-index: 1;
	}
}

.campaign-page-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	z-index: 3;
	@extend .custom-selector;

	.campaign-info-wrapper {
		display: flex;
		justify-content: center;
		margin: 3.9vh 18.9vw 6.4vh 18.9vw;

		.avatar-wrapper {
			transform: translateY(-50%);
			margin-right: 24px;
			width: 155px;
			min-width: 155px;
			box-shadow: $box-shadow;
		}

		.info-2 {
			width: 100%;
			max-width: 620px;
			display: flex;
			flex-direction: column;
			margin-bottom: 3.3vh;

			.info-inner {
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-bottom: 3.3vh;

				.collection-name {
					color: $color-text-2;
					font-size: $font-size-xxmd;
				}

				.colletion-type {
					color: $color-text-4;
					font-size: $font-size-md;
				}

				.date-wrapper {
					width: 100%;
					min-height: 44px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					background: $color-6;
					border-radius: $border-radius-wrapper;
					padding: 12px 14px;

					span {
						font-size: $font-size-xsm;
					}

					.info {
						color: $color-text-4;
					}

					.date {
						color: $color-text-1;
						background: transparent;
					}
				}
			}

			.description {
				font-weight: 400;
				min-width: 620px;
			}
		}
	}

	.navigation-buttons {
		width: 100%;
		display: flex;
		justify-content: center;
		gap: 24px;
		margin-bottom: 82px;
	}

	.campaign-tabs {
		width: 100%;

		.ant-tabs-content {
			display: inline;
		}

		.ant-tabs-content-holder {
			margin: 0 12.8vw;
		}

		.ant-tabs-tab-btn {
			color: $color-text-2;
		}

		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			color: $color-text-2;
			font-weight: 500;
		}

		.ant-tabs-tab-btn:focus,
		.ant-tabs-tab-btn:active,
		.ant-tabs-tab-btn:hover {
			color: $color-text-2;
		}

		.ant-tabs-tab:hover {
			color: $color-text-2;
		}

		.ant-tabs-ink-bar {
			background: $color-1;
		}

		.campaign-details-container {
			// max-width: 1200px; // fixed width so flex-wrap doesn't grow
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-top: 18px;

			.campaign-details-box {
				width: 318px;
				height: 87px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-between;
				background: $color-7;
				padding: 17px 30px 24px 17px;
				margin: 8px;

				span {
					color: $color-text-1;
				}

				.label {
					font-size: $font-size-xsm;
				}

				.value {
					font-size: $font-size-xxmd;
				}
			}
		}

		.no-nfts-case {
			width: 100%;
			height: 200px;
			margin: 50px;
			background: $color-6;
			border: 1px dashed $color-4;
			border-radius: $border-radius-wrapper;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			span {
				color: $color-text-1;
			}
		}

		.stats-wrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.statistics-card-container {
				display: flex;
				justify-content: space-between;
				margin-top: 40px;

				border: $primary-border;
				border-radius: $border-radius-wrapper;
				padding: 32px;
				gap: 30px;

				.statistics-card-image {
					min-width: 230px;
					height: 230px;

					img {
						border-radius: $border-radius-wrapper;
						width: 100%;
						height: 100%;
					}
				}

				.stats-details-wrapper {
					width: 100%;

					span {
						color: $color-text-1;
					}

					.header {
						font-size: $font-size-md;
						font-weight: 700;
					}

					.stats-details-box {
						width: 318px;
						height: 87px;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: space-between;
						background: $color-7;
						padding: 17px 30px 24px 17px;
						margin: 8px 8px 8px 0;

						span {
							color: $color-text-1;
						}

						.label {
							font-size: $font-size-xsm;
						}

						.value {
							font-size: $font-size-xxmd;
						}
					}
				}
			}
		}
	}

	.options-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 3.3vh;

		.tertiary-btn {
			margin: 0 0 0 20px;
		}

		.selector-wrapper {
			background: $color-2;
			border-radius: $border-radius-wrapper;
			display: flex;
			justify-content: center;
		}

		.dropdown-wrapper {
			background: $color-2;
		}

		// Search bar
		$tl: 0.5s; // transition length

		.search-box {
			position: absolute;
			transition: width $tl, border-radius $tl, background $tl, box-shadow $tl;
			width: 100%;
			height: 100%;
			border-radius: $border-radius-btn;
			border: $primary-border;
			padding-left: 35px;
			cursor: pointer;
			background: transparent;
			& + label .search-icon {
				color: $color-text-5;
			}
			&:hover {
				color: $color-text-2;
				border: $primary-border-hover;
				& + label .search-icon {
					color: $color-text-2;
				}
			}
			&:focus {
				border: $primary-border-hover;
				outline: 2px solid $color-3;
				box-shadow: none;
				font-weight: 400;
				color: $color-text-1;
				cursor: text;
				& + label .search-icon {
					color: $color-text-2;
				}
			}
		}

		::-webkit-input-placeholder {
			color: $color-text-2;
			font-weight: 400;
			font-size: $font-size-xsm;
		}

		#search-submit {
			display: none;
		}

		.search-icon {
			position: relative;
			color: $hover-color;
			margin: 0 10px;
			top: 8px;
			font-size: $font-size-sm;
			color: $color-text-5;
			cursor: pointer;
			&:hover {
				color: $hover-color;
			}
		}

		.search-box-wrapper {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.input-search {
			border-radius: $border-radius-btn;
			height: 100%;
			border: 0;
			margin: 0 5px 0 20px;
			position: relative;
			z-index: 10;
			width: 100%;
		}
	}

	.nft-card-list-container {
		min-height: 340px;
		display: flex;
		flex-wrap: wrap;
		column-gap: 24px;
		justify-content: flex-start;

		.empty-items {
			margin-top: 32px;
		}
	}

	.ant-tabs > .ant-tabs-nav {
		margin: 0 5.8vw;
	}
}
